<template>
	<v-container fluid>
		<v-layout row wrap>
			<v-flex xs12 md4>
				<v-card class="mt-3 mx-1 text-center" style="margin-top: 50px !important">
					<v-avatar size="100" style="margin-top: -50px">
						<image-input
							v-model="base64"
							class="elevation-5"
							:placeholder="user.imagen"
							style="overflow: hidden; border-radius: 50%; height: 100%; width: 100%"
							ref="imageInput"
						></image-input>
					</v-avatar>
					<div class="headline" v-text="api.user.nombre"></div>
					<div class="body-2" v-text="api.user.documento"></div>
					<div class="body-2" v-text="api.user.email"></div>
					<div class="body-1">{{ api.user.cargo }} - {{ api.user.departamento }}</div>
					<template v-if="user.preferences">
						<v-select
							solo
							text
							prepend-inner-icon="language"
							hide-details
							:items="[
								{ text: 'Español', value: 'es' },
								{ text: 'English', value: 'en' },
							]"
							v-model="settings.language"
							:label="'language' | trans"
							:placeholder="'language' | trans"
						></v-select>
						<v-switch :label="'email notifications' | trans" v-model="user.preferences.email_notifications"></v-switch>
						<v-switch :label="'web notifications' | trans" v-model="user.preferences.mobile_notifications"></v-switch>
						<v-switch :label="'email resume' | trans" v-model="user.preferences.email_resumen"></v-switch>
						<v-btn fab absolute bottom right color="primary" @click="savePreferences()">
							<v-icon>mdi-content-save</v-icon>
						</v-btn>
					</template>
				</v-card>
			</v-flex>
			<v-flex xs12 md4>
				<v-card class="mt-3 mx-1">
					<v-card-text>
						<v-form v-model="valid">
							<v-text-field
								:rules="[rules.required]"
								required
								:label="'name' | trans"
								v-model="user.nombre"
							></v-text-field>
							<v-text-field
								:rules="[rules.required]"
								required
								:label="'email' | trans"
								disabled
								v-model="user.email"
							></v-text-field>
							<v-text-field
								:rules="[rules.required]"
								required
								:label="'document' | trans"
								v-model="user.documento"
							></v-text-field>
							<v-text-field :label="'charge' | trans" v-model="user.cargo"></v-text-field>
							<v-text-field :label="'department' | trans" v-model="user.departamento"></v-text-field>
							<v-text-field :label="'phone_number' | trans" v-model="user.telefono"></v-text-field>
							<address-input class="ma-3" v-model="user.direccion"></address-input>
							<v-btn fab absolute bottom right color="primary" @click="save()" :disabled="!valid">
								<v-icon>mdi-content-save</v-icon>
							</v-btn>
						</v-form>
					</v-card-text>
				</v-card>
			</v-flex>
			<v-flex xs12 md4>
				<v-card class="mt-3 mx-1">
					<v-card-title primary-title class="title">{{ "password" | trans }}</v-card-title>
					<v-card-text>
						<v-form v-model="valid2">
							<v-text-field
								:rules="[rules.required, rules.min6]"
								required
								:label="'permissionmanager.password' | trans"
								type="password"
								autocomplete="new-password"
								v-model="password"
							></v-text-field>
							<v-text-field
								:rules="[rules.required, rules.min6, rules.coin]"
								required
								autocomplete="new-password"
								:label="'permissionmanager.password_confirmation' | trans"
								type="password"
								v-model="passwordConfirmation"
							></v-text-field>
							<v-btn
								@click="changePassword()"
								:disabled="!valid2 || password != passwordConfirmation"
								color="primary"
								block
								text
								>{{ "crud.save" | trans }}</v-btn
							>
						</v-form>
					</v-card-text>
				</v-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>
<script>
import AddressInput from "@/components/main/AddressInput";
import moment from "moment";
export default {
	components: { AddressInput },
	mounted() {
		this.user = JSON.parse(JSON.stringify(this.api.user));
		delete this.user.roles;
		delete this.user.permissions;
		if (!this.user.preferences) {
			this.user.preferences = {
				language: "es",
				mostrar_calendario: true,
				principal: "home",
				interval: "1 month",
				email_notifications: true,
				mobile_notifications: true,
				sms_notifications: false,
				email_resumen: false,
				chat: true,
			};
		}
	},
	methods: {
		async save() {
			var data = this.user;
			try {
				var resp = await this.api.put(`users/${this.user.id}`, data);
				this.api.user = Object.assign(this.api.user, resp.data);
				this.api.storage.set("user", JSON.parse(JSON.stringify(this.api.user)));
				this.api.successful(resp);
			} catch (error) {
				this.api.handleError(error);
			}
		},
		async updateClient() {
			try {
				var resp = await this.api.put(`clientes/${this.api.user.cliente_id}`, {
					first_name: this.user.cliente.first_name,
					nit: this.user.cliente.nit,
					email: this.user.cliente.email,
					direccion: this.user.cliente.direccion,
					telefono: this.user.cliente.telefono,
					invoice_number: this.user.cliente.invoice_number,
					regimen_fiscal: this.user.cliente.regimen_fiscal,
					prefix: this.user.cliente.prefix,
					suffix: this.user.cliente.suffix,
					resolucion_dian: this.user.cliente.resolucion_dian,
					cabecera_factura: this.user.cliente.cabecera_factura,
					pie_factura: this.user.cliente.pie_factura,
					color: this.user.cliente.color,

					fecha_inicio_facturacion: moment(this.user.cliente.fecha_inicio_facturacion).format("YYYY-MM-DD"),
					fecha_fin_facturacion: moment(this.user.cliente.fecha_fin_facturacion).format("YYYY-MM-DD"),
					rango_inicio_facturacion: this.user.cliente.rango_inicio_facturacion,
					rango_fin_facturacion: this.user.cliente.rango_fin_facturacion,
					numero_resolucion_facturacion: this.user.cliente.numero_resolucion_facturacion,
					clave_tecnica: this.user.cliente.clave_tecnica,

					//   Contingencia
					contingencia_fecha_inicio_facturacion: moment(this.user.cliente.contingencia_fecha_inicio_facturacion).format(
						"YYYY-MM-DD"
					),
					contingencia_fecha_fin_facturacion: moment(this.user.cliente.contingencia_fecha_fin_facturacion).format(
						"YYYY-MM-DD"
					),
					contingencia_rango_inicio_facturacion: this.user.cliente.contingencia_rango_inicio_facturacion,
					contingencia_rango_fin_facturacion: this.user.cliente.contingencia_rango_fin_facturacion,
					contingencia_numero_resolucion_facturacion: this.user.cliente.contingencia_numero_resolucion_facturacion,
					contingencia_clave_tecnica: this.user.cliente.contingencia_clave_tecnica,
					contingencia_prefijo: this.user.cliente.contingencia_prefijo,
				});
				this.api.user.cliente = Object.assign(this.api.user.cliente, resp.data);
				this.api.storage.set("user", JSON.parse(JSON.stringify(this.api.user)));
				this.api.successful(resp);
			} catch (error) {
				this.api.handleError(error);
			}
		},
		async changePassword() {
			try {
				await this.api.post("changePassword", {
					password: this.password,
				});
				this.api.successful("Contrasena Actualizada Satisfactoriamente");
			} catch (error) {
				this.api.handleError(error);
			}
		},
		getImage() {
			this.$refs.imageInput.askFile();
		},
		async uploadImage() {
			try {
				var resp = await this.api.put(`users/${this.api.user.id}`, {
					image: this.base64,
				});
				this.api.user.imagen = resp.data.imagen + "?rand=" + Math.random();
				this.user.imagen = resp.data.imagen + "?rand=" + Math.random();
				this.api.storage.set("user", JSON.parse(JSON.stringify(this.api.user)));
				this.api.successful(resp);
			} catch (error) {
				console.error(error);
			}
		},
		async savePreferences() {
			var data = this.user;
			try {
				var resp = await this.api.put(`users/${this.user.id}`, {
					preferences: data.preferences,
				});
				this.api.user = Object.assign(this.api.user, resp.data);
				this.api.storage.set("user", JSON.parse(JSON.stringify(this.api.user)));
				this.api.successful(resp);
			} catch (error) {
				this.api.handleError(error);
			}
			this.changeLanguage();
		},
		async changeLanguage() {
			await this.api.loadTranslations(this.settings.language);
			this.$moment.locale(this.settings.language + "-us");
			this.saveSettings();
		},
	},
	data() {
		return {
			password: "",
			passwordConfirmation: "",
			base64: null,
			valid: false,
			valid2: false,
			user: {},
			rules: {
				required: (v) => !!v || "Campo Requerido",
				min6: (v) => v.length > 5 || "Min 6 Caracteres",
				coin: (v) => v == this.password || "Las contrasena no coiciden",
			},
		};
	},
	watch: {
		base64() {
			this.uploadImage();
		},
	},
};
</script>
